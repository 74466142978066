<template>
  <div class="w-screen h-full bg-black md:hidden">
    <div class="header fixed top-0 w-full z-10" ref="header">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        @click="scrollTo('introduction')"
        />
      </div>
    </div>
    <div class="fake-header opacity-0 top-0 w-full">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        />
      </div>
    </div>
    <div class="event_result bg-buyBackground flex" :style="fullHeight">
      <div class="m-auto">
        <div class="heading flex justify-center items-center flex-wrap">
          <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold">
            我們正在處理您的付款
          </h3>
        </div>
        <hr class="w-1/5 text-white mx-auto mt-8 border-2">
        <div class="form mx-4 mt-10 before" autocomplete="off">
          <p class="text-white text-lg tracking-widest text-center">
            2022 Halfway Studio 跑者專屬優惠<br>
            南港老爺行旅 9 折住房優惠
          </p>
          <div class="bg-white rounded-lg my-10 py-2">
            <!-- 優惠碼 -->
            <div class="flex justify-between mx-4 my-4">
              <p class="text-xl tracking-widest">優惠碼</p>
              <p class="text-xl tracking-widest">2022Halfway</p>
            </div>
          </div>
          <div class="flex justify-center mt-10 pb-10">
            <div class="hearts flex justify-end items-center w-1/4 px-5">
              <img class="w-1/3" src="@/assets/heart.png" />
            </div>
            <button class="mx-auto bg-white text-buyBackground border-black border-2 shadow-inputActive rounded-lg text-lg px-3 py-2" @click="toLineOfficial">
              前往 LINE 官方帳號
            </button>
            <div class="hearts flex justify-start items-center w-1/4 px-5">
              <img class="w-1/3" src="@/assets/heart.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer fixed bottom-0 w-screen z-10 bg-black flex justify-center items-center py-3" ref="footer">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
    </div>
    <div class="fake-footer opacity-0 bottom-0 w-screen z-10 bg-black flex justify-center items-center py-3">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
    </div>
  </div>
</template>

<script>
import liff from '@line/liff';
import stores from './stores.json'
// import axios from 'axios';

export default {
  name: 'App',
  data(){
    return {
      stores,
      headerHeight: 0,
      footerHeight: 0,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 0.75] // [0.25, 0.75] if you want a 25% offset!
      },
    }
  },
  computed: {
    fullHeight: function(){
      let windowHeight = window.innerHeight;
      return {
        'height': `${windowHeight - this.headerHeight - this.footerHeight}px`,
      }
    }
  },
  methods: {
    toLineOfficial(){
      window.location.href = 'https://line.me/R/ti/p/%40408ivtuk'
    },
  },
  created(){
    window.onload = function(){
      document.addEventListener('touchstart', function(e) {
        if(e.touches.length > 1){
          e.preventDefault();
        }
      })
      document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
      })
    }
    liff
      .init({
        liffId: '1656804955-a04wej50', // Use own liffId
      })
      .then(() => {
        if (liff.isLoggedIn()) {
          this.isBuyShow = true;
        }
      })
  },
  mounted(){
    this.headerHeight = this.$refs.header.clientHeight;
    this.footerHeight = this.$refs.footer.clientHeight;
  }
}
</script>

<style scoped>
  .carousel-image {
    height: 200px;
    width: 200px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>