<template>
  <div class="w-screen h-full bg-black md:hidden">
    <transition name="fade">
      <div v-show="noticePopup" class="notice_popup overflow-y-auto h-screen w-screen fixed bg-black bg-opacity-90 z-20 flex items-center justify-center flex-wrap">
        <div class="flex justify-between items-center mx-3">
          <div class="space w-1/12"></div>
          <img
          class=" w-1/2 my-10"
          src="@/assets/event_notice_heading.png"
          />
          <img
            class="w-1/12"
            src="@/assets/cancel_button.png"
            @click="toggleNotice"
          />
        </div>
        <p class="content text-white tracking-wider leading-loose font-Noto-Sans mx-10 text-lg text-justify pb-20">
          關於更改、退費事項<br><br>
          一、增減人數<br>
          增加人數，請於出發日前第八日以上來信客服部，提供訂單編號與訂購人姓名、該加入旅人的姓名、電話、電子信箱、出生年月日及身份證字號(若為外國人，請提供護照號碼)。減少人數，請參照取消退費條款。<br><br>
          二、取消退費<br>
          不可抗力因素之更改票券及退費辦法：若因天災、疫情等不可抗拒之因素，由政府、該縣市主關機關發布停止上班、活動暫緩或取消之訊息，本場活動也將延期或是取消，並協助改期或全額退費。<br><br>
          三、退費規定<br>
          24小時內全額退費，超過時間恕不退款，但接受將票券名額轉讓給親友。<br><br>
          四、改期流程<br>
          1. 提供購票之訂單編號、購票姓名、電話及購票信箱等資訊。<br>
          2. 等待回信，將為您改期並重新發出確認信件。<br><br>
          五、退款流程<br>
          1. 提供購票之訂單編號、購票姓名、電話及購票信箱等資訊。<br>
          2. 確認退款後，請等待7-10個工作天待金融機構確認，實際退款日則依照使用發卡銀行規定為準。<br><br>
          ＊本商品為特殊活動，退費條款如上述說明為主，如有任何疑問，請於正常工作上班時段來信告知主辦方。<br>
          工作時段：<br>週一至週日 10:00-21:00<br><br><br>
          ※ 活動前若台灣發生大型嚴重新冠肺炎群聚感染，或政府公告規範管制，主辦單位將取消此場活動，敬請見諒！<br>
          ※ 本活動以散步的方式進行，結束時間將以實際狀況為準，煩請理解。<br>
          ※ 提供酒品恕不指定，將由協辦酒吧安排特色調酒等品項。<br>
          ※ 請注意每場活動地點及時間不同 。(以活動網頁揭露為主)<br><br>
          －注意事項－<br>
          ・本活動未滿 18 歲不得參與。<br>
          ・尊重生命，理性飲酒，喝酒請勿開車。<br>
          ・孕婦，慢性病或因酒精引起身體不適者，建議勿飲用酒精類飲品，飲用請自行斟酌。<br>
          ・部分合作店家或許有服務費、低消及訂位等限制。<br>
          ・各優惠與活動的實際情況請依各合作店家之公告為準。<br>
          ・活動主辦方保有修改活動之權利與最終解釋權。<br>
          ・謹慎衡量自身酒量，以免發生危險！<br>
          ・歡樂同時，請注意隨身物品。<br>
          ・購買票券視同同意並授權「Halfway Studio 2022酒精路跑巡禮」活動拍攝、修飾、使用、公開展示本人之肖像。<br>
          ・購票者視同完全理解相關要求與規定，同意遵守以上規範來遵守活動<br>
        </p>
      </div>
    </transition>
    <div class="header fixed top-0 w-full z-10" ref="header">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        @click="scrollTo('introduction')"
        />
      </div>
      <div class="menu flex bg-menuDeactive flex-row">
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 px-1" :class="{
          'bg-menuDeactive': menuActive.description == false,
          'bg-primary': menuActive.description == true,
        }" @click="scrollTo('description')">活動說明</span>
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 px-1" :class="{
          'bg-menuDeactive': menuActive.content == false,
          'bg-primary': menuActive.content == true,
        }" @click="scrollTo('content')">活動內容</span>
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 px-1" :class="{
          'bg-menuDeactive': menuActive.location == false,
          'bg-primary': menuActive.location == true,
        }" @click="scrollTo('location')">時間地點</span>
        <span class="font-Noto-Sans text-base text-white w-3/12 text-center py-2 px-1" :class="{
          'bg-menuDeactive': menuActive.ticketType == false,
          'bg-primary': menuActive.ticketType == true,
        }" @click="scrollTo('ticketType')">票種×價格</span>
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 px-1" :class="{
          'bg-menuDeactive': menuActive.cooperate == false,
          'bg-primary': menuActive.cooperate == true,
        }" @click="scrollTo('cooperate')">協辦單位</span>
      </div>
    </div>
    <div class="fake-header opacity-0 top-0 w-full">
      <div class="bar flex justify-between items-center pr-3 pt-5 pb-5 bg-black">
        <img
        class="w-4/5"
        src="@/assets/logo.png"
        />
      </div>
      <div class="menu flex mb-5 bg-menuDeactive flex-row">
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 mx-1" :class="{
          'bg-menuDeactive': menuActive.description == false,
          'bg-primary': menuActive.description == true,
        }" @click="scrollTo('description')">活動說明</span>
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 mx-1" :class="{
          'bg-menuDeactive': menuActive.content == false,
          'bg-primary': menuActive.content == true,
        }" @click="scrollTo('content')">活動內容</span>
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 mx-1" :class="{
          'bg-menuDeactive': menuActive.location == false,
          'bg-primary': menuActive.location == true,
        }" @click="scrollTo('location')">時間地點</span>
        <span class="font-Noto-Sans text-base text-white w-3/12 text-center py-2 mx-1" :class="{
          'bg-menuDeactive': menuActive.ticketType == false,
          'bg-primary': menuActive.ticketType == true,
        }" @click="scrollTo('ticketType')">票種×價格</span>
        <span class="font-Noto-Sans text-base text-white w-1/5 text-center py-2 mx-1" :class="{
          'bg-menuDeactive': menuActive.cooperate == false,
          'bg-primary': menuActive.cooperate == true,
        }" @click="scrollTo('cooperate')">合作夥伴</span>
      </div>
    </div>
    <div class="introduction flex flex-wrap justify-center" ref="introduction" id="introduction" v-waypoint="{
      active: true,
      callback: onScroll,
      option: intersectionOptions,
    }">
      <img
        class="w-full h-full py-10 pr-6"
        src="@/assets/main.png"
      />
      <span class="font-Noto-Sans font-bold text-white text-base mx-6 tracking-wider text-center mt-10">
        新的一年，你的新希望是什麼呢？<br>
        去年遺留下來的遺憾、煩惱又是什麼呢？<br><br>
        輾轉難眠的夜晚、超時工作的疲勞、因疫情而未萌芽的戀情、每日兩點一線的通勤距離。<br><br>
        2022年，期許是轉變的半途，<br>白天你獨自為了理想奮鬥，<br>晚上讓我們伴你解憂、陪你消愁。<br><br>
        我們是伴途工作室，<br>
        即將在今年的二月至六月結合40間以上知名酒吧舉辦數場全新的酒精嘉年華。<br><br>
        邀請飲酒文化愛好者們一起手握調酒，<br>一同體驗Bar Hopping x 闖關活動。<br><br>
        一起在台北的夜河中照亮彼此的夜晚，<br>飲燃心的生機。<br>
      </span>
      <span class="font-Noto-Sans text-hashTag text-lg mt-6">
        #Halfwayfestival #2022barhopping
      </span>
    </div>
    <div class="event_description flex items-center flex-wrap my-16" :style="fullHeight" ref="description">
      <div class="w-full">
        <h3 class="text-white text-center font-Noto-Sans text-3xl font-bold" id="description" v-waypoint="{
          active: true,
          callback: onScroll,
        }">
          活動說明
        </h3>
        <hr class="w-1/5 text-white mx-auto mt-8 border-2">
        <p class="text-white text-center font-Poppin tracking-wider font-bold mt-10">
          情人節前後的這兩天<br>
          讓我們一起度過四站<br>
          分享彼此的點滴<br>
          用酒精擺渡悲苦的情緒<br>
          三小時左右的相識時間<br>
          八百公尺莫的步行距離<br><br>
          從生疏變成熟識  原本只是打過照面  最後每日通電<br>
          二杯調酒＋六個 Shot 的旅程<br>
          從微醺走進愛戀<br>
          半途我們羞澀  絆途中間歡樂  伴途最後耳熱<br><br>
          2/13 , 2/17<br>
          讓我們一起讓體驗旅途<br>
          找到﹝半﹞的部首  從點頭絆成摯友  從陌生伴成戀人<br>
          Stranger To <span class="text-primary">Lover</span> , Hopping Together<br>
        </p>
      </div>
    </div>
    <div class="event_content pt-16 bg-gradient-to-b from-black via-primary to-black" ref="content">
      <h3 class="text-white text-center font-Noto-Sans text-3xl font-bold" id="content" v-waypoint="{
        active: true,
        callback: onScroll,
      }">
        活動內容
      </h3>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="station_1 py-14 flex items-center" :style="fullHeight">
        <div class="item_content">
          <div class="big_model w-screen text-center">
            <span class="font-Poppin text-8xl text-transparent bg-clip-text bg-gradient-to-br from-stationFrom to-stationTo mx-auto">1</span>
          </div>
          <h2 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-5">第一站</h2>
          <hr class="w-1/5 text-white mx-auto mt-8 border-2">
          <h3 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-10 leading-relaxed">
            嗨<br>
            Stranger<br>
            很高興認識你
          </h3>
        </div>
      </div>
      <div class="station_2 py-14 flex items-center" :style="fullHeight">
        <div class="item_content">
          <div class="big_model w-screen text-center">
            <span class="font-Poppin text-8xl text-transparent bg-clip-text bg-gradient-to-br from-stationFrom to-stationTo mx-auto">
              2
            </span>
          </div>
          <h2 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-5">第二站</h2>
          <hr class="w-1/5 text-white mx-auto mt-8 border-2">
          <h3 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-10 leading-relaxed">
            生命中的不期而遇<br>
            都是你努力的驚喜
          </h3>
        </div>
      </div>
      <div class="station_3 py-14 flex items-center" :style="fullHeight">
        <div class="item_content">
          <div class="big_model w-screen text-center">
            <span class="font-Poppin text-8xl text-transparent bg-clip-text bg-gradient-to-br from-stationFrom to-stationTo mx-auto">
              3
            </span>
          </div>
          <h2 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-5">第三站</h2>
          <hr class="w-1/5 text-white mx-auto mt-8 border-2">
          <h3 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-10 leading-relaxed">
            或許我無法為你遮風擋雨<br>
            但至少讓我為你擋下這杯酒
          </h3>
        </div>
      </div>
      <div class="station_4 py-14 flex items-center" :style="fullHeight">
        <div class="item_content">
          <div class="big_model w-screen text-center">
            <span class="font-Poppin text-8xl text-transparent bg-clip-text bg-gradient-to-br from-stationFrom to-stationTo mx-auto">
              4
            </span>
          </div>
          <h2 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-5">第四站</h2>
          <hr class="w-1/5 text-white mx-auto mt-8 border-2">
          <h3 class="text-white text-3xl font-bold font-Noto-Sans text-center mt-10 leading-relaxed">
            不需要大冒險更不需耍心機<br>
            我所說的一字一句<br>
            都是我的真心真意
          </h3>
        </div>
      </div>
      <div class="notice text-center font-Noto-Sans py-14">
        <h2 class="text-white leading-loose font-bold text-xl">﹝貼心提醒﹞</h2>
        <p class="text-white leading-loose tracking-wider">
          玩家將會透過心理測驗分散至八條路線，<br>
          並經由工作人員統一帶隊。<br>
          請玩家務必準時到達路線第一站，<br>
          以確保體驗完整遊戲內容。<br>
        </p>
      </div>
    </div>
    <div class="event_location pt-16 pb-10" ref="location">
      <h3 class="text-white text-center font-Noto-Sans text-3xl font-bold" id="location" v-waypoint="{
        active: true,
        callback: onScroll,
      }">
        時間地點
      </h3>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="event-block mx-4 mt-10">
        <span class="font-Noto-Sans text-white font-bold text-lg ml-5">地點</span>
        <img src="@/assets/location.png" />
      </div>
      <flickity class="my-10" ref="flickity" :options="flickityOptions">
        <div class="block carousel-image" v-for="(store, index) in stores" :key="index">
          <img class="h-5/6" :src="`/assets/store/${store.source}`">
          <p class="text-white font-Poppin mt-3 text-center whitespace-nowrap">
            {{ store.name }}
          </p>
        </div>
      </flickity>
      <div class="event-block mx-4 mt-10">
        <span class="font-Noto-Sans text-white font-bold text-lg ml-5">報名日</span>
        <img src="@/assets/signup_dates.png" />
      </div>
      <div class="event-block mx-4 mt-10">
        <span class="font-Noto-Sans text-white font-bold text-lg ml-5">報到日</span>
        <img src="@/assets/event_dates.png" />
      </div>
      <div class="event-block mx-4 mt-10">
        <span class="font-Noto-Sans text-white font-bold text-lg ml-5">報到時間</span>
        <img src="@/assets/first_echelon.png" />
        <img src="@/assets/second_echelon.png" />
      </div>
      <h4 class="text-primary text-center font-Noto-Sans text-xl font-bold" @click="toggleNotice">
        注意事項點我
      </h4>
      <hr class="w-1/6 text-primary mx-auto mt-2 border-2">
    </div>
    <div class="event_ticketType pt-16" ref="ticketType">
      <h3 class="text-white text-center font-Noto-Sans text-3xl font-bold" id="ticketType" v-waypoint="{
        active: true,
        callback: onScroll,
      }">
        票種與價格
      </h3>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="ticket-block mx-4 mt-10">
        <img src="@/assets/ticket_price.png" />
        <img src="@/assets/ticket_include.png" />
      </div>
      <div class="ticket-block mx-4 mt-10">
        <img src="@/assets/including_icon.png" class="w-1/12 mx-auto pb-10" />
        <h3 class="text-white font-Noto-Sans text-xl font-bold text-center leading-loose">
          ﹝微醺愛戀不出包﹞
        </h3>
        <p class=" text-white text-center font-base tracking-wider leading-loose font-Noto-Sans">
          Passion24 解酒果汁飲料、<br>
          精美活動細節手冊、Shot 杯一個<br>
          各式貼紙、嘔吐袋以及特殊禮品 × 1<br>
        </p>
      </div>
    </div>
    <div class="event_cooperate pt-16" ref="cooperate">
      <h3 class="text-white text-center font-Noto-Sans text-3xl font-bold" id="cooperate" v-waypoint="{
        active: true,
        callback: onScroll,
      }">
        協辦單位
      </h3>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="cooperate-store mx-2 mt-10 flex justify-between items-center">
        <img class=" w-1/3 px-2" src="@/assets/zima.png" />
        <img class=" w-1/3 px-2" src="@/assets/Stupid_bar.png" />
        <img class=" w-1/3 px-2" src="@/assets/the_place.png" />
      </div>
    </div>
    <div class="event_buy mt-16 bg-buyBackground pt-10" ref="buy" v-show="isBuyShow && !isBuyComplete && !isPaymentShow">
      <div class="heading flex justify-center items-center flex-nowrap">
        <div class="hearts flex justify-between items-center w-1/4">
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
        </div>
        <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold" v-waypoint="{
          active: true,
          callback: onScroll,
        }">
          手刀購買
        </h3>
        <div class="hearts flex justify-between items-center w-1/4">
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
        </div>
      </div>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="form mx-4 mt-10 before" autocomplete="off">
        <!-- 姓名 -->
        <input 
          class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputActive"
          placeholder="姓名"
          v-model="form.name"
          autocomplete="new-name"
        >
        <!-- 性別 -->
        <div class="gender-selector flex justify-between my-5">
          <button class="text-xl font-Noto-Sans border-2 bg-secondBlack rounded-lg text-center w-1/2 mr-5 py-5 border-white" :class="{
            'text-gray': (form.male == false),
            'text-white': (form.male == true),
          }" @click="changeGender(true)">
            生理男
          </button>
          <button class="text-xl font-Noto-Sans border-2 bg-secondBlack rounded-lg text-center w-1/2 ml-5 py-5 border-white" :class="{
            'text-gray': (form.male == true),
            'text-white': (form.male == false),
          }" @click="changeGender(false)">
            生理女
          </button>
        </div>
        <!-- 聯絡電話 -->
        <input 
          class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputDeactive"
          placeholder="聯絡電話"
          v-model="form.phone"
          autocomplete="new-phone"
        >
        <!-- 電子郵件 -->
        <input 
          class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputDeactive"
          placeholder="電子郵件"
          v-model="form.email"
          autocapitalize="new-email"
        >
        <!-- 購買票數 -->
        <select class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputDeactive" v-model="form.ticketNumber">
          <option selected disabled value="0">票券張數</option>
          <option v-for="number in 10" :key="number">
            <option :value="number">{{ number }}</option>
          </option>
        </select>
        <!-- 寄送地址 -->
        <input 
          class="my-5 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white bg-secondBlack text-center w-full py-2 shadow-inputDeactive"
          placeholder="聯絡地址"
          v-model="form.address"
          autocomplete="new-address"
        >
        <!-- 備註 -->
        <p class="text-white text-sm leading-loose tracking-widest">
          ＊為享有完整體驗，請填寫你方便收取包裹的地址，我們將在活動開始前，把「微醺愛戀不出包」寄給你
        </p>
        <div class="flex justify-center mt-10 pb-10">
          <div class="hearts flex justify-end items-center w-1/4 px-5">
            <img class="w-1/3" src="@/assets/heart.png" />
          </div>
          <button class="mx-auto bg-white text-buyBackground shadow-inputActive rounded-lg text-lg px-10 py-2" @click="buy">
            前往付款
          </button>
          <div class="hearts flex justify-start items-center w-1/4 px-5">
            <img class="w-1/3" src="@/assets/heart.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="event_pay mt-16 bg-buyBackground pt-10" ref="payment" v-show="isBuyShow && !isBuyComplete && isPaymentShow">
      <div class="heading flex justify-center items-center flex-nowrap">
        <div class="hearts flex justify-between items-center w-1/4">
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
        </div>
        <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold" v-waypoint="{
          active: true,
          callback: onScroll,
        }">
          手刀購買
        </h3>
        <div class="hearts flex justify-between items-center w-1/4">
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
          <img class="w-1/4" src="@/assets/heart.png" />
        </div>
      </div>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="form mx-4 mt-10 before" autocomplete="off">
        <!-- 卡號 -->
        <div
          class="h-10 my-5 px-2 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white text-center w-full py-2 shadow-inputActive"
          ref="card"
        />
        <!-- 到期日 -->
        <div
          class="h-10 my-5 px-2 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white text-center w-full py-2 shadow-inputActive"
          ref="expired"
        />
        <!-- 安全碼 -->
        <div
          class="h-10 my-5 px-2 font-Noto-Sans border-2 rounded-lg text-xl text-white border-white text-center w-full py-2 shadow-inputActive"
          ref="cvv"
        />
        <div class="flex justify-center mt-10 pb-10">
          <div class="hearts flex justify-end items-center w-1/4 px-5">
            <img class="w-1/3" src="@/assets/heart.png" />
          </div>
          <button class="mx-auto bg-white text-buyBackground shadow-inputActive rounded-lg text-lg px-10 py-2" @click="pay">
            完成付款
          </button>
          <div class="hearts flex justify-start items-center w-1/4 px-5">
            <img class="w-1/3" src="@/assets/heart.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="event_result mt-16 bg-buyBackground pt-10" v-show="isBuyShow && isBuyComplete && !isPaymentShow">
      <div class="heading flex justify-center items-center flex-nowrap">
        <h3 class="text-white text-center font-Noto-Sans px-3 text-3xl font-bold">
          2 步驟完成付款
        </h3>
      </div>
      <hr class="w-1/5 text-white mx-auto mt-8 border-2">
      <div class="form mx-4 mt-10 before" autocomplete="off">
        <p class="text-white text-lg tracking-widest">
          Step 1：購票資訊如下，請先使用網路轉帳至下方帳戶
        </p>
        <div class="bg-white rounded-lg my-10 py-2">
          <!-- 門票數量 -->
          <div class="flex justify-between mx-4 my-4">
            <p class="text-xl">門票 {{ this.form.ticketNumber }} 張</p>
            <p class="text-xl">${{ this.form.ticketNumber * 1280 }}</p>
          </div>
          <!-- 匯款銀行 -->
          <div class="flex justify-between mx-4 my-4">
            <p class="text-xl">匯款銀行</p>
            <p class="text-xl">中國信託 822</p>
          </div>
          <!-- 匯款帳號 -->
          <div class="flex justify-between mx-4 my-4">
            <p class="text-xl">匯款帳號</p>
            <p class="text-xl">226540-074202</p>
          </div>
        </div>
        <p class="text-white text-lg tracking-widest">
          Step 2：付款後，請至「HalfwayStudio」LINE官方帳號回傳匯款帳號後5碼
        </p>
        <div class="flex justify-center mt-10 pb-10">
          <div class="hearts flex justify-end items-center w-1/4 px-5">
            <img class="w-1/3" src="@/assets/heart.png" />
          </div>
          <button class="mx-auto bg-white text-buyBackground border-black border-2 shadow-inputActive rounded-lg text-lg px-3 py-2" @click="toLineOfficial">
            前往 LINE 官方帳號
          </button>
          <div class="hearts flex justify-start items-center w-1/4 px-5">
            <img class="w-1/3" src="@/assets/heart.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer fixed bottom-0 w-screen z-10 bg-black flex justify-between items-center py-3" ref="footer">
      <img
      class="ml-3 w-2/3 px-3"
      src="@/assets/drunk_notice.png"
      />
      <img
      class="w-1/4 mr-3"
      src="@/assets/buy_button.png"
      @click="toBuy()"
      />
    </div>
    <div class="fake-footer opacity-0 w-screen bg-black flex justify-between items-center">
      <img
      class="ml-3 w-5/12"
      src="@/assets/drunk_notice.png"
      />
      <img
      class="w-1/2"
      src="@/assets/buy_button.png"
      />
    </div>
  </div>
</template>

<script>
import liff from '@line/liff';
import Flickity from 'vue-flickity';
import stores from './stores.json'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    Flickity,
  },
  data(){
    return {
      isPaymentShow: false,
      isCreditCardValid: false,
      isBuyComplete: false,
      isBuyShow: false,
      noticePopup: false,
      stores,
      flickityOptions: {
        prevNextButtons: false,
        wrapAround: true,
        autoPlay: 1500,
        pageDots: false,
        resize: true,
      },
      headerHeight: 0,
      footerHeight: 0,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 0.75] // [0.25, 0.75] if you want a 25% offset!
      },
      menuActive: {
        description: false,
        location: false,
        content: false,
        ticketType: false,
        cooperate: false,
        notice: false,
      },
      form: {
        userId: "",
        name: "",
        male: true,
        phone: "",
        email: "",
        ticketNumber: 0,
        address: "",
        prime: ""
      },
    }
  },
  computed: {
    fullHeight: function(){
      let windowHeight = window.innerHeight;
      return {
        'height': `${windowHeight - this.headerHeight - this.footerHeight}px`,
      }
    }
  },
  methods: {
    toBuy(){
      if(navigator.userAgent.indexOf("Instagram") != -1){
        alert("請使用外部瀏覽器開啟進行購買！");
      }else{
        if (liff.isLoggedIn()) {
          this.scrollTo('buy')
        }else{
          liff.login()
        }
      }
    },
    pay(){
      if(!this.isCreditCardValid){
        alert("請確認信用卡資訊正確！");
        return;
      }
      TPDirect.card.getPrime(result => {
        if (result.status !== 0) {
          console.log("刷卡失敗，請重新嘗試！");
          return;
        }
        this.form.prime = result.card.prime;
        this.submitForm()
      })
    },
    submitForm(){
      let bodyForm = new FormData();
      bodyForm.append('ticket_number', this.form.ticketNumber);
      bodyForm.append('phone', this.form.phone);
      bodyForm.append('name', this.form.name);
      bodyForm.append('email', this.form.email);
      bodyForm.append('gender', (this.form.male == true) ? "1" : "0");
      bodyForm.append('address', this.form.address);
      bodyForm.append('lineId', liff.getContext().userId);
      bodyForm.append('prime', this.form.prime);
      axios
        .post(
          'https://api.hwstud.io/v2/new-ticket.php',
          bodyForm
        )
        .then((response) => {
          if(response.data == '-1'){
            alert("此帳號已購買過！");
          }else{
            let responseData = response.data;
            if (responseData.status == 0) {
              window.location.href = responseData.paymentUrl
            }else{
              console.log(responseData)
            }
          }
        })
    },
    toLineOfficial(){
      window.location.href = 'https://line.me/R/ti/p/%40408ivtuk'
    },
    toggleNotice(){
      this.noticePopup = !this.noticePopup;
    },
    getHeader(){
      console.log(this.$refs)
    },
    positionChange(waypointState){
      console.log(waypointState.going)
    },
    onScroll({going, ...test}){
      const nowInstance = test.el.getAttribute("id");
      if(going == 'in'){
        if(nowInstance != null && nowInstance != "introduction"){
          this.resetAllMenu();
          this.menuActive[test.el.getAttribute("id")] = true;
        }
        if(nowInstance == 'introduction'){
          this.resetAllMenu();
        }
      }
    },
    resetAllMenu(){
      for(let menu in this.menuActive){
        this.menuActive[menu] = false;
      }
    },
    changeGender(isMale){
      this.form.male = isMale;
    },
    scrollTo(ref){
      var headerHeight = this.$refs["header"].clientHeight;
      var element = this.$refs[ref];
      var top = element.offsetTop - headerHeight;

      window.scrollTo({
        top,
        behavior: 'smooth'
      })
    },
    checkPhone(){
      const phoneNumber = this.form.phone;
      if(/^[0-9]{10}$/.test(phoneNumber)){
        return true;
      }else{
        alert("請確認手機號碼填寫正確！")
        return false;
      }
    },
    checkEmail(){
      const email = this.form.email;
      if(/[\w-]+@([\w-]+\.)+[\w-]+/.test(email)){
        return true;
      }else{
        alert("請確認電子郵件填寫正確！")
        return false;
      }
    },
    buy(){
      liff
        .getFriendship()
        .then((data) => {
          if (data.friendFlag == true) {
            if(this.form.name.trim() != ""){
              if (this.checkPhone()) {
                if (this.checkEmail()) {
                  if(this.form.ticketNumber > 0){
                    if (this.form.address.trim() != "") {
                      // Send Data to Server
                      this.isPaymentShow = true;
                    }else{
                      alert("請輸入寄送地址！");
                    }
                  }else{
                    alert("請選擇購買張數！")
                  }
                }
              }
            }else{
              alert("請輸入購買人姓名！")
            }
          }else{
            alert("請先加入 Line 好友後繼續購買！");
            window.location.href = 'https://line.me/R/ti/p/%40408ivtuk'
          }
        })
    }
  },
  created(){
    window.onload = function(){
      document.addEventListener('touchstart', function(e) {
        if(e.touches.length > 1){
          e.preventDefault();
        }
      })
      document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
      })
    }
    liff
      .init({
        liffId: '1656804955-a04wej50', // Use own liffId
      })
      .then(() => {
        if (liff.isLoggedIn()) {
          this.isBuyShow = true;
        }
      })
  },
  mounted(){
    this.headerHeight = this.$refs.header.clientHeight;
    this.footerHeight = this.$refs.footer.clientHeight;

    /* global TPDirect */
    TPDirect.setupSDK(123123, 'app_bwmOKgdMPjm6mnFYDZENvgIriIoyREa9uR1CoLvSpA0pYFwDFqnnnxfDuXjc', 'production')

    const fields = {
      number: {
        element: this.$refs.card,
        placeholder: '卡號',
      },
      expirationDate: {
        element: this.$refs.expired,
        placeholder: '到期日',
      },
      ccv: {
        element: this.$refs.cvv,
        placeholder: '安全碼',
      },
    }

    TPDirect.card.setup({
      fields,
      styles: {
        'input': {
          color: 'white'
        },
      }
    })

    TPDirect.card.onUpdate(update => {
      this.isCreditCardValid = update.canGetPrime;
    })
  }
}
</script>

<style scoped>
  .carousel-image {
    height: 200px;
    width: 200px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>